import { services, roles } from './config';
import axios from 'axios';

export default {
    login: ({ username, password }) => {
        return axios({
            method: 'POST',
            url: `${services.users}/auth/local`,
            headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
            data: { username, password },
            responseType: 'json',
        }).then(res => {
            if (res.data.role !== roles.ADMIN) {
                throw new Error('Unauthorized');
            }
            localStorage.setItem('token', res.data.accessToken);
            localStorage.setItem('tokenExpiry', res.data.accessTokenExpiry);
            localStorage.setItem('role', res.data.role);
        }).catch(err => {
            throw Error(err?.response?.data?.message ? err.response.data.message : err.message);
        });
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiry');
        localStorage.removeItem('role');
        return Promise.resolve();
    },
    checkError: error => {
        console.error(error);
    },
    checkAuth: () => {
        const token = localStorage.getItem('token');
        const tokenExpiry = parseInt(localStorage.getItem('tokenExpiry'))
        if (!token || tokenExpiry < new Date().getTime()) {
            return Promise.reject({ redirectTo: '/login' });
        }
        return Promise.resolve();
    },
    getPermissions: () => {
        const role = localStorage.getItem('role');
        if (!role) {
            return Promise.reject();
        }
        return Promise.resolve(role);
    }
};
