import React, { useState } from 'react';
import ReactGeoJSON from 'react-geojson';
import Grid from '@material-ui/core/Grid';
import { useInput } from 'ra-core';
import { googleMapsKey } from '../config';

function mapRerenderFix(map) {
    // ReactGeoJSON reloading page map not initialized.
    // if the map was allredy initialized we have to remove #react-geojson and then ReactGeoJSON runs map initialization.
    if (!map && document.querySelector('#react-geojson')) {
        document.querySelector('#react-geojson').remove()
        const keywords = ['maps.googleapis'];
        window.google = undefined;
        // deletes all google maps scripts, cuz map initialization creates new google maps scripts (duplication error).
        const scripts = document.head.getElementsByTagName('script');
        for (let i = scripts.length - 1; i >= 0; i--) {
            let scriptSource = scripts[i].getAttribute('src');
            if (scriptSource != null) {
                if (keywords.filter(item => scriptSource.includes(item)).length) {
                    scripts[i].remove()
                }
            }
        }
    }
}

function revertCoverage(coverage) {
    if (coverage) {
        coverage = coverage.geofencing_zones[0];
        for (let i = 0; i < coverage.features.length; i++) {
            const coordinate = coverage.features[i].geometry.coordinates[0]
            coverage.features[i].properties = { Name: 'coverage' };
            if (JSON.stringify(coordinate[0]) === JSON.stringify(coordinate[coordinate.length - 1])) {
                coordinate.pop()
            }
        }
    }
    return coverage;
}

function convertCoverage(coverage) {
    coverage.geofencing_zones = [{ features: coverage.features }];
    coverage.geofencing_zones[0].type = 'FeatureCollection';
    for(let i = 0; i < coverage.geofencing_zones[0].features.length; i++) {
        coverage.features[i].geometry.coordinates[0].push(coverage.features[i].geometry.coordinates[0][0])
    }
    delete coverage.features;
    delete coverage.type
    return coverage;
}

function getCoverageLngLat(coverage) {
    const point = coverage?.geofencing_zones[0]?.features[0].geometry?.coordinates[0][0] || [14.505743407677393, 46.05369975716354];
    return { lng: point[0], lat: point[1] };
}

export const MapCoverageField = ({ record }) => {
    if (!record.coverage) return null;

    const [map, setMap] = useState();
    mapRerenderFix(map);

    return <Grid item xs={12} style={{ height: '400px' }}>
        <ReactGeoJSON
            apiKey={googleMapsKey}
            onMapInitiated={setMap}
            scriptLibraries='geometry'
            existingArea={revertCoverage(record.coverage)}
            zoom={12}
            editable={false}
            center={getCoverageLngLat(record.coverage)}
        />
    </Grid>;
}

export const MapCoverageInput = (props) => {
    const { record, source } = props;

    const { input: { onChange } } = useInput({ source });
    const [map, setMap] = useState();
    mapRerenderFix(map);

    return <Grid item xs={12} style={{ height: '400px' }}>
        <ReactGeoJSON
            apiKey={googleMapsKey}
            onMapInitiated={setMap}
            scriptLibraries='geometry'
            existingArea={!!record.coverage && revertCoverage(record.coverage)}
            onSave={data => onChange(convertCoverage(data))}
            zoom={12}
            editable={true}
            center={getCoverageLngLat(record.coverage)}
        />
    </Grid>;
};