import React, { useState } from "react";
import { Box, Typography } from '@material-ui/core';

import '../../node_modules/react-vis/dist/style.css';
import {
    VerticalBarSeries, XAxis, YAxis, HorizontalGridLines, VerticalGridLines, LineMarkSeries, Hint, DiscreteColorLegend, FlexibleWidthXYPlot, ChartLabel,
} from 'react-vis';


export const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export const modeColorDictionary = {
    transit: '#a43dd9',
    walking: '#448aff',
    cycling: '#4caf50',
    car: '#ff5252',
    taxi: '#febb1b',
    motorbike: '#795548',
    microMobility: '#2d9298',
};

export const RenderGraph = (props) => {
    const { data, type, height, yAxisText, xAxisText } = props;

    const [tooltip, setTooltip] = useState(null);

    return (
        <>
            <div style={{ height }}>
                <FlexibleWidthXYPlot xType="ordinal" height={height} margin={{ left: 50, bottom: 50, top: 35 }}>
                    <XAxis />
                    <YAxis tickFormat={v => v / 1000} />
                    <ChartLabel
                        text={yAxisText}
                        includeMargin={false}
                        xPercent={0}
                        yPercent={0.7}
                        style={{
                            transform: 'rotate(270)'
                        }}
                    />
                    <ChartLabel
                        text={xAxisText}
                        includeMargin={false}
                        xPercent={0.49}
                        yPercent={1.2}
                    />
                    <VerticalGridLines />
                    <HorizontalGridLines />
                    {tooltip && <Hint value={tooltip}><div style={{ color: 'black' }}>{Math.round(tooltip.y / 1000)}</div></Hint>}
                    {type === 'line_series' ? <DiscreteColorLegend
                        orientation='horizontal'
                        style={{ position: 'absolute', left: '50px', top: '1px' }}
                        items={Object.entries(data).map(([k, v]) => ({ title: k, color: v.color }))} //cannot change stroke size, https://github.com/uber/react-vis/issues/860
                    /> : null}
                    {
                        type === 'line_series' ? Object.entries(data).map(([k, v]) => (<LineMarkSeries colorType="literal" data={v.data} color={v.color} key={k} animation size={4} />))
                            :
                            <VerticalBarSeries colorType="literal" data={data} animation onValueMouseOver={e => setTooltip(e)} />
                    }
                </FlexibleWidthXYPlot>
            </div>
        </>
    );
}

