import React from 'react';
import {
    List, Datagrid, ShowButton, Show, TextField, EmailField, ImageField, NumberField, DateField, TextInput, ImageInput,
    NumberInput, ArrayInput, SelectField, SelectInput, Edit, SimpleForm, SimpleFormIterator, Labeled, Create, required, SimpleShowLayout
} from 'react-admin';
import { locales } from '../config'
import { ColorField, ColorInput } from 'react-admin-color-input';
import { EditToolbar, getCountries } from '../helper';

import Grid from '@material-ui/core/Grid';
import { SanitizedGrid } from '../layout/index';
import '../layout/style.css';

const TextArrayField = ({ record, source }) => {
    const array = record[source]
    if (typeof array === 'undefined' || array === null || array.length === 0) {
        return <div />
    } else {
        return (
            <>
                {array.map(item => <p key={item}>{item}</p>)}
            </>
        )
    }
}
TextArrayField.defaultProps = { addLabel: true }

const MyImageComponent = props => {
    if (props?.record?.customization?.image?.data) {
        const { data } = props.record.customization.image;
        return <> <div><Labeled label="Image" /></div> <img style={{ "maxHeight": "10rem", "cursor": "pointer" }} src={data} onClick={(e) => { e.stopPropagation(); window.open(data) }}></img> </>;
    }
    return <span />;
}

export const CompanyList = (props) => (
    <List {...props} bulkActionButtons={false} sort={{ field: 'updatedAt', order: 'ASC' }}>
        <Datagrid>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="name" />
            <EmailField label="Email" source="email" />
            <TextField label="Telephone" source="telephone.number" />
            <ShowButton />
        </Datagrid>
    </List>
);

export const CompanyShow = (props) => (
    <Show {...props} >
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="name" />
            <EmailField label="Email" source="email" />
            <TextField label="CountryCode" source="telephone.countryCode" />
            <TextField label="Telephone" source="telephone.number" />
            <TextArrayField source="domains" />
            <TextField label="Address" source="address.address" />
            <TextField label="City" source="address.city" />
            <TextField label="Zip code" source="address.zipCode" />
            <SelectField label="Country" source="address.country" choices={getCountries} />
            <NumberField label="Longitude" source="address.geoLocation.lng" options={{ maximumFractionDigits: 8 }} />
            <NumberField label="Latitude" source="address.geoLocation.lat" options={{ maximumFractionDigits: 8 }} />
            <MyImageComponent source="customization.image" />
            <ColorField label="Background color" source="customization.primaryColor" />
            <ColorField label="Font color" source="customization.secondaryColor" />
            <ColorField label="Tertiary color" source="customization.tertiaryColor" />
            <DateField label="Created" source="createdAt" locales={locales.sl} showTime />
            <DateField label="Updated" source="updatedAt" locales={locales.sl} showTime />
        </SimpleShowLayout>
    </Show>
);

const Inputs = (props) => (
    <SanitizedGrid container spacing={2}>
        {props.isEdit ? <Grid item xs={12}><TextInput disabled className="rem" label="ID" source="id" /></Grid> : null}
        <Grid item xs={12}><TextInput label="Name" source="name" validate={[required()]} /></Grid>
        <Grid item xs={12}><TextInput label="Email" source="email" type="email" validate={[required()]} /></Grid>
        <Grid item xs={12}><TextInput label="Country Code" source="telephone.countryCode" /></Grid>
        <Grid item xs={12}><TextInput label="Telephone" source="telephone.number" /></Grid>
        <Grid item xs={12}>
            <ArrayInput label="Domains" source="domains">
                <SimpleFormIterator> <TextInput /> </SimpleFormIterator>
            </ArrayInput>
        </Grid>
        <Grid item xs={12}><TextInput label="Address" source="address.address" /></Grid>
        <Grid item xs={12}><TextInput label="City" source="address.city" /></Grid>
        <Grid item xs={12}><TextInput label="Zip code" source="address.zipCode" /></Grid>
        <Grid item xs={12}><SelectInput label="Country" optionText="name" source="address.country" choices={getCountries} /></Grid>
        <Grid item xs={12}><NumberInput label="Longitude" source="address.geoLocation.lng" /></Grid>
        <Grid item xs={12}><NumberInput label="Latitude" source="address.geoLocation.lat" /></Grid>
        <Grid item xs={12}>
            <ImageInput source="customization.image" label="Image" accept="image/*" placeholder={<p>Drop company image here</p>} multiple={false}>
                <ImageField source="data" title="title" />
            </ImageInput>
        </Grid>
        <Grid item xs={12}><ColorInput label="Background color" source="customization.primaryColor" /></Grid>
        <Grid item xs={12}><ColorInput label="Font color" source="customization.secondaryColor" /></Grid>
        <Grid item xs={12}><ColorInput label="Tertiary color" source="customization.tertiaryColor" /></Grid>
    </SanitizedGrid >
);

export const CompanyEdit = (props) => (
    <Edit {...props}>
        <SimpleForm toolbar={<EditToolbar />} redirect="show">
            <Inputs isEdit={true} />
        </SimpleForm>
    </Edit>
);

export const CompanyCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <Inputs />
        </SimpleForm>
    </Create>
);