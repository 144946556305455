import React, { useState } from "react";
import { TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel } from '@material-ui/core';
import LineChart from './lineChart';
import BarGraph from './barGraph';
import moment from 'moment';
import { useStyles } from '../styles';

export default () => {

    const initDates = { from: moment().add(-3, 'months').add(1, 'days').format('YYYY-MM-DD'), to: moment().add(1, 'days').format('YYYY-MM-DD') };
    const [dates, setDates] = useState(initDates)
    const [interval, setInterval] = useState('months');
    const [checked, setChecked] = useState(false);

    const handleChecked = (event) => {
        setDates(event.target.checked ? { from: null, to: null } : initDates);
        setInterval('months');
        setChecked(event.target.checked);
    }

    const handleSelect = (event) => {
        setInterval(event.target.value);
    }

    const classes = useStyles();

    const Filters = () => {
        return (
            <>
                <FormControl className={classes.formControl}>
                    <InputLabel>Interval</InputLabel>
                    <Select
                        value={interval}
                        onChange={handleSelect}>
                        <MenuItem disabled={checked} value={'days'}>Days</MenuItem>
                        <MenuItem value={'months'}>Months</MenuItem>
                        <MenuItem disabled={checked} value={'years'}>Years</MenuItem>
                    </Select>
                </FormControl>
                <FormControlLabel
                    style={{ margin: 10 }}
                    control={<Checkbox checked={checked} onChange={handleChecked} name="all_time" />}
                    label="All Time"
                />
                {!checked &&
                    <>
                        <TextField
                            label="From"
                            type="date"
                            onChange={(e) => setDates({ ...dates, from: e.target.value })}
                            defaultValue={dates.from}
                            className={classes.textField}
                        />
                        <TextField
                            label="To"
                            type="date"
                            onChange={(e) => setDates({ ...dates, to: e.target.value })}
                            defaultValue={dates.to}
                            className={classes.textField}
                        />
                    </>
                }
            </>
        );
    }

    return (
        <>
            <Filters />
            <LineChart height={400} width={800} from={dates.from} to={dates.to} interval={interval} yAxisText="DISTANCE (km)" xAxisText="TIME" />
            <BarGraph height={400} width={800} from={dates.from} to={dates.to} interval={interval} yAxisText="DISTANCE (km)" xAxisText="MODE" />
        </>
    );
};
