import React, { useEffect, useState } from "react";
import { GET_LIST, LinearProgress } from 'react-admin';
import moment from 'moment';
import dataProvider from '../../dataProvider/index';
import { modeColorDictionary, RenderGraph } from '../helper'

export default (props) => {

    const { height, width, from, to, yAxisText, xAxisText, interval } = props;

    const [data, setData] = useState({ loading: true, time: {} });

    const fetchData = async () => {
        const t = {};

        const filter = { aggregate: true, groupBy: 'time,mode', interval }
        if (from) filter.from = moment(from).format('X');
        if (to) filter.to = moment(to).format('X');

        await dataProvider(GET_LIST, 'paths/saved', {
            filter
        }).then((routes) => {
            routes.data.forEach(e => {
                e.aggregated.forEach(e2 => {
                    t[e2.id.mode] = { color: '', data: [] };
                })
            })
            routes.data.forEach(e => {
                Object.keys(t).forEach(k => {
                    t[k].data.push({ x: moment(e.id).format('DD.MM'), y: 0 })
                })
            })
            routes.data.forEach(e => {
                e.aggregated.forEach(e2 => {
                    t[e2.id.mode].color = modeColorDictionary[e2.id.mode];
                    const index = t[e2.id.mode].data.findIndex(e3 => e3.x === moment(e.id).format('DD.MM'));
                    t[e2.id.mode].data[index] = { x: moment(e.id).format('DD.MM'), y: e2.meterSum }
                })
            })
        }).catch(console.error);

        setData({ loading: false, time: t });
    };

    useEffect(() => {
        fetchData();
    }, [from, to, interval]);

    return (
        <>
            {
                data.loading ? <div style={{ padding: 20 }}> <LinearProgress style={{ margin: "auto" }} /> </div>
                    : data.time ? <RenderGraph height={height} width={width} data={data.time} type="line_series" yAxisText={yAxisText} xAxisText={xAxisText} /> : <div>No data to show</div>
            }
        </>
    );
};
