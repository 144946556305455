import React, { useState, useEffect } from "react";
import '../../../node_modules/react-vis/dist/style.css';

import dataProvider from '../../dataProvider/index';
import decodePolyline from 'decode-google-map-polyline';

import { GET_LIST, LinearProgress } from 'react-admin';

import { GoogleMap, withScriptjs, withGoogleMap, Polyline } from "react-google-maps";

import { modeColorDictionary } from '../helper';

import { googleMapsKey } from '../../config';

import { DiscreteColorLegend } from 'react-vis';

const MyMapComponent = withScriptjs(withGoogleMap(props => {
    const { encodedPolylines } = props;
    return <GoogleMap
        defaultZoom={11}
        defaultCenter={{ lat: 46.05415090504756, lng: 14.521015584468842 }}> {/* UKC */}
        {encodedPolylines.map((e, i) => {
            return <Polyline key={i} path={decodePolyline(e.polyline)} options={{ strokeColor: modeColorDictionary[e.mode] }} />
        })}
    </GoogleMap>
}));

const MapWrapper = props => {
    const { encodedPolylines } = props;
    return (
        <>
            <div style={{ marginBottom: '10px' }}>
                <DiscreteColorLegend
                    orientation='horizontal'
                    items={Object.entries(modeColorDictionary).map(([k, v]) => ({ title: k, color: v }))}
                />
            </div>
            <MyMapComponent
                encodedPolylines={encodedPolylines}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${googleMapsKey}`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%`, width: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
            />
        </>);
}

export default () => {

    const [data, setData] = useState({ loading: true, encodedPolylines: [] });

    const fetchData = async () => {
        const p = [];
        await dataProvider(GET_LIST, 'paths/saved', {
        }).then((routes) => {
            routes.data.forEach(e => {
                Object.values(e.segments).forEach(e2 => {
                    if (e2.polyline) p.push({ mode: e2.mode, polyline: e2.polyline })
                })
            })
        }).catch(console.error);

        setData({ loading: false, encodedPolylines: p });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {
                data.loading ? <div style={{ padding: 20 }}> <LinearProgress style={{ margin: "auto" }} /> </div>
                    : data.encodedPolylines.length ? <MapWrapper encodedPolylines={data.encodedPolylines} /> : <div>No data to show</div>
            }
        </>
    );
}
