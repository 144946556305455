import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import {
    List, Datagrid, TextField, BooleanField, BooleanInput,
    SimpleForm, Edit, EditButton, useRedirect, TextInput, required 
} from 'react-admin';

import { EditToolbar, BackButtonToolbar } from '../helper';

import Grid from '@material-ui/core/Grid';
import { SanitizedGrid } from '../layout/index';
import '../layout/style.css';

import ReceiptIcon from '@material-ui/icons/Receipt';

import { Cronstrue, CronComponent } from './cron'

export const PluginList = (props) => {
    const SanitizeLogButton = (props) => {
        const redirect = useRedirect();
        return (
            <Button size="small" color="primary" fontSize="small" onClick={() => redirect('/records/' + props.record.id)}>
                <ReceiptIcon color="primary" fontSize="small" />
                Records
            </Button>
        );
    };
    return (
        <List {...props} bulkActionButtons={false} sort={{ field: 'updatedAt', order: 'ASC' }} filter={{ populate: 'record' }} >
            <Datagrid>
                <TextField label="ID" source="id" />
                <TextField label="Name" source="name" />
                <BooleanField label="Active" source="active" />
{/*                 <BooleanField label="Skedgo" source="systems.skedgo" /> */}
                <Cronstrue label="Interval" />
                <BooleanField label="Pulled Data Status" source="record.pulledDataStatus" />
                <BooleanField label="Processed Data Status" source="record.processedDataStatus" />
                <BooleanField label="Pushed Data Status" source="record.pushedDataStatus" />
                <SanitizeLogButton />
                <EditButton />
            </Datagrid>
        </List>
    );
};

export const Inputs = (props) => {
    const [active, setActive] = useState(props?.record?.active);
    return (
        <SanitizedGrid container spacing={2}>
            <Grid item xs={12}><BooleanInput label="Active" source="active" onChange={(e) => setActive(e)} /></Grid>
            {active &&
                <>
                    <Grid item xs={12}><TextInput label="Provider Name" source="provider.name" validate={[required()]} /></Grid>
                    <Grid item xs={6}><TextInput label="Address" source="provider.address" /></Grid>
                    <Grid item xs={6}><TextInput label="Phone" source="provider.phone" /></Grid>
                    <Grid item xs={6}><TextInput label="Website" source="provider.website" /></Grid>
                    <Grid item xs={6}><TextInput label="App Name" source="provider.appName" /></Grid>
                    <Grid item xs={6}><TextInput label="Android" source="provider.android" /></Grid>
                    <Grid item xs={6}><TextInput label="iOS" source="provider.ios" /></Grid>
{/*                     <Grid item xs={12}><BooleanInput label="Skedgo" source="systems.skedgo" /></Grid> */}
                    <Grid item xs={12}>
                        <CronComponent label="Interval" source="interval" />
                    </Grid>
                </>
            }
        </SanitizedGrid>
    )
}

export const PluginEdit = (props) => (
    <Edit {...props} actions={<BackButtonToolbar {...props} />}>
        <SimpleForm toolbar={<EditToolbar />} redirect="list">
            <Inputs />
        </SimpleForm>
    </Edit>
);