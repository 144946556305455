import React from 'react';
import {
    List, Datagrid, ShowButton, ReferenceInput,
    Show, SimpleShowLayout, Filter,
    TextField, DateField, SelectInput,
    ReferenceField, ArrayField
} from 'react-admin';
import { FullNameField, Amount } from '../helper';
import { locales, payments } from '../config';

export function PaymentMethod({ record = {} }) {
    return <span style={{ fontSize: "14px" }}> {record.details.brand} {record.details.last4}</span>;
}

const PaymentFilters = (props) => (
    <Filter {...props}>
        <ReferenceInput label="User" source="userID" reference="users" allowEmpty={false} sort={{ field: 'firstName', order: 'DESC' }} perPage={0}>
            <SelectInput optionText={<FullNameField />} />
        </ReferenceInput>
        <ReferenceInput label="Payment Method" source="paymentMethodID" reference="methods" link="show" allowEmpty={false} sort={{ field: 'details.brand', order: 'DESC' }} perPage={0}>
            <SelectInput optionText={<PaymentMethod />} />
        </ReferenceInput>
        <SelectInput label="Status" source="status" choices={payments.status} />
    </Filter>
);

export const PaymentsList = (props) => (
    <List {...props}
        filters={<PaymentFilters />}
        bulkActionButtons={false}
        sort={{ field: 'updatedAt', order: 'ASC' }}>
        <Datagrid>
            <TextField label="ID" source="id" />
            <ReferenceField label="User" source="userID" reference="users" link="show">
                <FullNameField />
            </ReferenceField>
            <ReferenceField label="Payment Method" source="paymentMethodID" reference="methods" link="show">
                <PaymentMethod />
            </ReferenceField>
            <Amount label='Amount' />
            <TextField label="Status" source="status" />
            <ShowButton />
        </Datagrid>
    </List>
);

export const PaymentsShow = (props) => (
    <Show {...props} >
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="External ID" source="identifier" />
            <ReferenceField label="User" source="userID" reference="users" link="show">
                <FullNameField />
            </ReferenceField>
            <ReferenceField label="Payment Method" source="paymentMethodID" reference="methods" link="show">
                <PaymentMethod />
            </ReferenceField>
            <TextField label="Amount in cents" source="amount" />
            <TextField label="Currency" source="currency" />
            <TextField label="Status" source="status" />
            <ArrayField label="Status updates" source="statusHistory" >
                <Datagrid>
                    <TextField label="Status" source="status" />
                    <DateField label="Timestamp" source="timestamp" locales={locales.sl} showTime />
                </Datagrid>
            </ArrayField>
            <DateField label="Created" source="createdAt" locales={locales.sl} showTime />
            <DateField label="Updated" source="updatedAt" locales={locales.sl} showTime />
        </SimpleShowLayout>
    </Show>
);