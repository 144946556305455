import React from 'react';
import {
    List, Datagrid, ShowButton, Show, TextField, Filter, SimpleForm, required, BooleanInput,
    Create, Edit, DateField, SimpleShowLayout, TextInput, BooleanField, UrlField, Toolbar, SelectInput
} from 'react-admin';
import { locales, providers } from '../config'
import { HideEditButton } from '../helper';

import Grid from '@material-ui/core/Grid';
import { SanitizedGrid } from '../layout/index';
import '../layout/style.css';

import { MapCoverageInput, MapCoverageField } from './MapCoverage';

const ProvidersFilters = (props) => (
    <Filter {...props}>
        <TextField label="Name" source="name" />
        <BooleanInput label="Active" source="active" />
        <BooleanInput label="Archived" source="archived" />
    </Filter>
);

export const ProvidersList = (props) => (
    <List {...props} filters={<ProvidersFilters />} bulkActionButtons={false} sort={{ field: 'updatedAt', order: 'ASC' }}>
        <Datagrid>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="name" />
            <TextField label="Mode" source="mode" />
            <BooleanField label="Active" source="active" />
            <ShowButton />
        </Datagrid>
    </List>
);

export const ProvidersShow = (props) => (
    <Show {...props} actions={<HideEditButton />}>
        <SimpleShowLayout {...props}>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="name" />
            <TextField label="Mode" source="mode" />
            <TextField label="Phone" source="phone" />
            <TextField label="Website" source="website" />
            <TextField label="Address" source="address" />
            <UrlField label="GBFS url" source="gbfsUrl" />
            <BooleanField label="Active" source="active" />
            <MapCoverageField {...props} source='coverage' />
            <DateField label="Updated at" source="updatedAt" locales={locales.sl} showTime />
            <DateField label="Created at" source="createdAt" locales={locales.sl} showTime />
        </SimpleShowLayout>
    </Show>
);

const Inputs = (props) => (
    <SanitizedGrid container spacing={2}>
        {props.isEdit ? <Grid item xs={12}><TextInput disabled className="rem" label="ID" source="id" /></Grid> : null}
        <Grid item xs={12}><TextInput label="Name" source="name" validate={[required()]} /></Grid>
        <Grid item xs={12}><TextInput label="Phone" source="phone" /></Grid>
        <Grid item xs={12}><TextInput label="Website" source="website" /></Grid >
        <Grid item xs={12}><SelectInput label="Mode" source="mode" choices={providers.modes} validate={[required()]} /></Grid >
        <Grid item xs={12}><TextInput label="Address" source="address" /></Grid >
        <Grid item xs={12}><BooleanInput label="Active" source="active" /></Grid >
        <MapCoverageInput {...props} source='coverage' validate={[required()]} />
    </SanitizedGrid >
);

export const ProvidersEdit = (props) => (
    <Edit {...props}>
        <SimpleForm redirect="show" toolbar={<Toolbar />}>
            <Inputs isEdit={true} />
        </SimpleForm>
    </Edit>
);

export const ProvidersCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="show" toolbar={<Toolbar />}>
            <Inputs isCreate={true} />
        </SimpleForm>
    </Create>
);