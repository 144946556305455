import { services } from '../config';
import companiesDataProvider from '../companies/dataProvider';
import usersDataProvider from '../users/dataProvider';
import newsDataProvider from '../news/dataProvider';

export function modifyUrl(resource) {
    switch (resource) {
        case 'users':
            return services.users;
        case 'companies':
            return services.users;
        case 'methods':
            return services.payments;
        case 'payments':
            return services.payments;
        case 'preferences':
            return services.routes;
        case 'paths/saved':
            return services.routes;
        case 'news':
            return services.informator;
        case 'plugins/records':
            return services.tspOrchestrator;
        case 'plugins':
            return services.tspOrchestrator;
        case 'providers':
            return services.tspOrchestrator;
        default:
            throw new Error(`Undefined resource: ${resource}.`);
    }
}

export function modifyId(type, o) {
    const stringify_o = JSON.stringify(o);
    switch (type) {
        case 'out':
            return JSON.parse(stringify_o.replace(new RegExp(/"ids"|"id"/gmi, 'g'), '"_id"'));
        case 'in':
            return JSON.parse(stringify_o.replace(new RegExp('_id', 'g'), 'id'));
    }
}

export async function modifyData(type, resource, data) {
    if (!data) return data;
    switch (resource) {
        case 'users':
            return await usersDataProvider(type, data);
        case 'companies':
            return await companiesDataProvider(type, data);
        case 'news':
            return await newsDataProvider(type, data);
        default:
            return data;
    }
}

export const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file.rawFile);
    });