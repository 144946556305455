import React, { useState } from 'react';
import {
    List, Datagrid, ShowButton, Show, TextField, Filter, SimpleForm, required, ArrayInput, SimpleFormIterator, BooleanInput, DateInput,
    Create, Edit, DateField, ArrayField, SimpleShowLayout, TextInput, ImageInput, ImageField, DateTimeInput, SelectInput, BooleanField,
} from 'react-admin';
import { locales, news } from '../config'
import { MyImageComponent, HideEditButton, convertToISO } from '../helper';

import Grid from '@material-ui/core/Grid';
import { SanitizedGrid } from '../layout/index';
import '../layout/style.css';

const NewsFilters = (props) => (
    <Filter {...props}>
        <SelectInput label="Type" source="type" allowEmpty={false} choices={news.types} />
        <SelectInput label="Language" source="language" allowEmpty={false} choices={news.language} />
        <DateInput parse={v => '>' + v} label="Valid From Start" source="validFrom" />
        <DateInput parse={v => '<' + v} label="Valid From End" source="_validFrom" />
        <DateInput parse={v => '>' + v} label="Valid To Start" source="validTo" />
        <DateInput parse={v => '<' + v} label="Valid To End" source="_validTo" />
        <BooleanInput label="Archived" source="archived" />
    </Filter>
);

export const NewsList = (props) => (
    <List {...props} filters={<NewsFilters />} bulkActionButtons={false} sort={{ field: 'updatedAt', order: 'ASC' }}>
        <Datagrid>
            <TextField label="ID" source="id" />
            <TextField label="Title" source="title" />
            <TextField label="Type" source="type" />
            <TextField label="Language" source="language" />
            <DateField label="Valid From" source="validFrom" locales={locales.sl} showTime />
            <DateField label="Valid To" source="validTo" locales={locales.sl} showTime />
            <ShowButton />
        </Datagrid>
    </List>
);

const Fields = (props) => {
    const isNews = props.record.type === 'news';
    const isTimeless = props.record.timeless;
    return (
        <SimpleShowLayout {...props}>
            <TextField label="ID" source="id" />
            <TextField label="Title" source="title" />
            <TextField label="Type" source="type" />
            <TextField label="Language" source="language" />
            {isNews && !isTimeless &&
                <>
                    <DateField label="Valid From" source="validFrom" locales={locales.sl} showTime />
                    <DateField label="Valid To" source="validTo" locales={locales.sl} showTime />
                </>
            }
            <ArrayField label="Information" source="information" >
                <Datagrid>
                    <TextField label="Title" source="title" />
                    <TextField label="Description" source="description" />
                    <MyImageComponent source="image" />
                </Datagrid>
            </ArrayField>
            {isNews &&
                <BooleanField label="Timeless" source="timeless" />
            }
            <BooleanField label="Active" source="active" />
            <DateField label="Created" source="createdAt" locales={locales.sl} showTime />
        </SimpleShowLayout>
    )
};

export const NewsShow = (props) => (
    <Show {...props} actions={<HideEditButton />}>
        <Fields {...props} />
    </Show>
);

const Inputs = (props) => {
    const [type, setType] = useState(props.record.type);
    const [disabled, setDisabled] = useState(props.record.timeless);
    const [validFrom, setValidFrom] = useState(convertToISO(props.record.validFrom));
    return (
        <SanitizedGrid container spacing={2}>
            {props.isEdit ? <Grid item xs={12}><TextInput disabled className="rem" label="ID" source="id" /></Grid> : null}
            <Grid item xs={12}><TextInput label="Title" source="title" validate={[required()]} /></Grid>
            <Grid item xs={12}><SelectInput label="Type" source="type" allowEmpty={false} choices={news.types} onChange={(event) => setType(event.target.value)} validate={[required()]} /></Grid>
            <Grid item xs={12}><SelectInput label="Language" source="language" allowEmpty={false} choices={news.language} validate={[required()]} /></Grid>
            {type === 'news' &&
                <Grid item xs={12}><BooleanInput label="Timeless" source="timeless" onChange={(value) => setDisabled(value)} /></Grid>
            }
            {type === 'news' && !disabled &&
                <>
                    <Grid item xs={12}>
                        <DateTimeInput
                            inputProps={{ min: convertToISO(new Date()) }}
                            onChange={event => { setValidFrom(event.target.value) }}
                            label="Valid From"
                            source="validFrom"
                            validate={[required()]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DateTimeInput
                            inputProps={{ min: validFrom }}
                            label="Valid To"
                            source="validTo"
                            validate={[required()]}
                        />
                    </Grid>
                </>
            }
            <Grid item xs={12}>
                <ArrayInput label="Information" source="information" validate={[required()]}>
                    <SimpleFormIterator>
                        <TextInput label="Title" source="title" validate={[required()]} />
                        <TextInput label="Description" source="description" validate={[required()]} />
                        <ImageInput source="image" label="Image" accept="image/*" placeholder={<p>Drop image here</p>} multiple={false}>
                            <ImageField source="data" title="title" />
                        </ImageInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </Grid>
            <Grid item xs={12}><BooleanInput label="Active" source="active" /></Grid>
        </SanitizedGrid>
    )
};

export const NewsEdit = (props) => (
    <Edit {...props}>
        <SimpleForm redirect="show">
            <Inputs isEdit={true} />
        </SimpleForm>
    </Edit>
);

export const NewsCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <Inputs />
        </SimpleForm>
    </Create>
);
