
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    textField: {
        margin: 10
    },
    formControl: {
        margin: 10,
        minWidth: 160,
    },
}));