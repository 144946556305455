
import React from 'react';
import { useInput, Labeled } from 'react-admin';
import Cron, { HEADER } from 'react-cron-generator';
import 'react-cron-generator/dist/cron-builder.css';
import './bootstrap.min.css';

import cronstrue from 'cronstrue';

export const Cronstrue = (props) => cronstrue.toString(props?.record?.interval);

const convertCronExpression = (cron) => {
    if (!cron) return cron;
    return cron
        .substring(2, cron.length - 2)
        .replace('SUN', '0')
        .replace('MON', '1')
        .replace('TUE', '2')
        .replace('WED', '3')
        .replace('THU', '4')
        .replace('FRI', '5')
        .replace('SAT', '6')
}

const revertCronExpression = (cron) => {
    if (!cron) return cron;
    const split = cron.split(" ");
    const days = split
        .pop()
        .replace('0', 'SUN')
        .replace('1', 'MON')
        .replace('2', 'TUE')
        .replace('3', 'WED')
        .replace('4', 'THU')
        .replace('5', 'FRI')
        .replace('6', 'SAT')

    return `0 ${split.join(' ')} ${days} *`;
}

export const CronComponent = (props) => {
    const { input: { value, onChange } } = useInput(props);
    return (
        <>
            <Labeled label={props.label} />
            <Cron
                value={revertCronExpression(value)}
                onChange={(val) => onChange(convertCronExpression(val))}
                showResultText={true}
                options={{ headers: [HEADER.MINUTES, HEADER.HOURLY, HEADER.DAILY, HEADER.WEEKLY] }}
            />
        </>
    );
};