import React from 'react';
import { render } from 'react-dom';
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom';

import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';

import authProvider from './authProvider';
import dataProvider from './dataProvider/index.js';

import { UserList, UserShow, UserEdit } from './users/index.js';
import { CompanyList, CompanyShow, CompanyEdit, CompanyCreate } from './companies/index.js';
import { PaymentMethodsList, PaymentMethodsShow, PaymentMethodsEdit } from './paymentMethods';
import { PreferencesList, PreferencesShow } from './preferences';
import { NewsList, NewsShow, NewsEdit, NewsCreate } from './news';
import { PaymentsList, PaymentsShow } from './payments';
import { PluginList, PluginEdit } from './plugins';
import { ProvidersList, ProvidersEdit, ProvidersShow, ProvidersCreate } from './providers';

import Records from './plugins/records';
import Dashboard from './dashboard/index';

import UsersIcon from '@material-ui/icons/Person';
import CompanyIcon from '@material-ui/icons/Business';
import PaymentIcon from '@material-ui/icons/Payment';
import TuneIcon from '@material-ui/icons/Tune';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import MoneyIcon from '@material-ui/icons/Money';
import PowerIcon from '@material-ui/icons/Power';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';

import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useMediaQuery } from 'react-responsive';

const App = () => {

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    const customRoutes = [<Route exact path="/records/:pluginID" component={Records} />]

    const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en', { allowMissing: true });
    return (
        <Admin dashboard={isMobile ? null : Dashboard} customRoutes={customRoutes} authProvider={authProvider} dataProvider={dataProvider} i18nProvider={i18nProvider}>
            <Resource icon={UsersIcon} options={{ label: 'Users' }} name='users' list={UserList} show={UserShow} edit={UserEdit} />
            <Resource icon={CompanyIcon} options={{ label: 'Companies' }} name='companies' list={CompanyList} show={CompanyShow} edit={CompanyEdit} create={CompanyCreate} />
            <Resource icon={TuneIcon} options={{ label: 'Preferences' }} name='preferences' list={PreferencesList} show={PreferencesShow} />
            <Resource icon={AnnouncementIcon} options={{ label: 'News' }} name='news' list={NewsList} show={NewsShow} edit={NewsEdit} create={NewsCreate} />
            <Resource icon={PaymentIcon} options={{ label: 'Payment Methods' }} name='methods' list={PaymentMethodsList} show={PaymentMethodsShow} edit={PaymentMethodsEdit} />
            <Resource icon={MoneyIcon} options={{ label: 'Transactions' }} name='payments' list={PaymentsList} show={PaymentsShow} />
            <Resource icon={PowerIcon} options={{ label: 'Plugins' }} name='plugins' list={PluginList} edit={PluginEdit} />
            <Resource icon={AirportShuttleIcon} options={{ label: 'Providers' }} name='providers' list={ProvidersList} show={ProvidersShow} edit={ProvidersEdit} create={ProvidersCreate} />
            <Resource name='plugins/records' />
            <ToastContainer transition={Zoom} style={{ width: "auto", height: "150px", fontSize: "x-large" }} />
        </Admin>
    )
};

render(<App />, document.getElementById('root'));