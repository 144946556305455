import React from 'react';
import { locales } from '../config'
import {
    List, Datagrid, TextField, BooleanField, DateField, BooleanInput, Filter, Pagination
} from 'react-admin';
import { RecordsToolbar, convertToISO } from '../helper';

const App = (props) => {
    const pluginID = props.match.params.pluginID;

    const initProps = {
        basePath: "/",
        hasCreate: false,
        hasEdit: false,
        hasList: true,
        hasShow: false,
        location: { pathname: "/", search: "", hash: "", state: undefined },
        match: { path: "/", url: "/", isExact: true, params: {} },
        options: {},
        permissions: null,
        resource: "plugins/records"
    };

    const RecordFilters = (props) => (
        <Filter {...props}>
            <BooleanInput label="Processed Data Status" source="processedDataStatus" />
            <BooleanInput label="Pulled Data Status" source="pulledDataStatus" />
            <BooleanInput label="Pushed Data Status" source="pushedDataStatus" />
        </Filter>
    );

    const SanitizedPagination = (props) => {
        const extendedProps = {
            ...props,
            count: props.total ?? 0
        }
        return <Pagination {...extendedProps} />;
    }

    const JSONPrettify = (props) => {
        return <div>{props?.record?.metadata ? <pre><h4>Metadata:</h4>{JSON.stringify(props?.record?.metadata, null, 2)}</pre> : 'Metadata is empty!'}</div>;
    }

    const recordRowStyle = (record) => ({
        backgroundColor: !record?.pulledDataStatus || !record?.processedDataStatus || !record?.pushedDataStatus ? '#ffb2b2' : null,
    });

    return (
        <List {...initProps}
            filterDefaultValues={{ pluginID }}
            filters={<RecordFilters />}
            bulkActionButtons={false}
            pagination={<SanitizedPagination />}
            actions={<RecordsToolbar />}
            perPage={25}
            sort={{ field: 'createdAt', order: 'ASC' }}>
            <Datagrid rowStyle={recordRowStyle} expand={<JSONPrettify />}>
                <TextField label="ID" source="id" />
                <DateField label="Created" source="createdAt" locales={locales.sl} showTime />
                <BooleanField label="Pulled Data Status" source="pulledDataStatus" />
                <BooleanField label="Processed Data Status" source="processedDataStatus" />
                <BooleanField label="Pushed Data Status" source="pushedDataStatus" />
            </Datagrid>
        </List>
    );
}

export default App;
