import React, { useEffect, useState } from "react";
import { Card, Divider, ListItem, CardHeader, List, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { GET_LIST } from 'react-admin';
import { useStyles } from '../styles';
import moment from 'moment';
import MoneyIcon from '@material-ui/icons/Money';
import dataProvider from '../../dataProvider/index';

export default () => {

    const [data, setData] = useState({
        loading: true,
        payments: [],
    });

    const fetchData = async () => {
        let payments = [];
        await dataProvider(GET_LIST, 'payments', {
            filter: {
                'createdAt': `>${moment().add(-30, 'days').toISOString()}`,
                '_createdAt': `<${moment().toISOString()}`
            },
            sort: { field: 'createdAt', order: 'ASC' }
        }).then((paymentsList) => {
            payments = paymentsList.data;
        }).catch(console.error);

        setData({ loading: false, payments });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const classes = useStyles();

    return (
        <Card className={classes.root} style={{ marginTop: '16px' }}>
            <CardHeader avatar={<MoneyIcon />} title="Latest Payments" subheader={`Last 30 Days: ${data.payments.length}`} />
            <Divider />
            <List dense={true}>
                {data.payments.slice(0, 5).map(payment => (
                    <ListItem
                        key={payment.id}
                        button
                        component={Link}
                        to={`/payments/${payment.id}/show`}>
                        <ListItemText
                            primary={payment.userID}
                            secondary={`Status: ${payment.status}`}
                        />
                        <ListItemSecondaryAction>
                            <div>
                                {payment.amount / 100}€
                            </div>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </Card>
    );
};
