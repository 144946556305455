import React, { useEffect, useState } from "react";
import { Card, Divider, ListItem, CardHeader, List, ListItemText } from '@material-ui/core';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { GET_LIST } from 'react-admin';
import { useStyles } from '../styles';
import PersonIcon from '@material-ui/icons/PersonAdd';
import dataProvider from '../../dataProvider/index';

export default () => {

    const [data, setData] = useState({
        loading: true,
        users: [],
    });

    const fetchData = async () => {
        let users = [];
        await dataProvider(GET_LIST, 'users', {
            filter: {
                'createdAt': `>${moment().add(-30, 'days').toISOString()}`,
                '_createdAt': `<${moment().toISOString()}`
            },
            sort: { field: 'createdAt', order: 'ASC' }
        }).then((usersList) => {
            users = usersList.data;
        }).catch(console.error);

        setData({ loading: false, users });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardHeader avatar={<PersonIcon />} title="New Users" subheader={`Last 30 Days: ${data.users.length}`} />
            <Divider />
            <List dense={true}>
                {data.users.slice(0, 5).map(user => (
                    <ListItem
                        key={user.id}
                        button
                        component={Link}
                        to={`/users/${user.id}/show`}
                    >
                        <ListItemText
                            primary={user.firstName + " " + user.lastName}
                            secondary={`Joined: ${moment(user.createdAt).format('D. M. YYYY')}`}
                        />
                    </ListItem>
                ))}
            </List>
        </Card>
    );
};
