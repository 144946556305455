import React, { useEffect, useState } from "react";
import { GET_LIST, LinearProgress } from 'react-admin';
import moment from 'moment';
import dataProvider from '../../dataProvider/index';
import { RenderGraph, modeColorDictionary } from '../helper';

export default (props) => {

    const { height, width, from, to, yAxisText, xAxisText, interval } = props;

    const [data, setData] = useState({ loading: true, modes: [] });

    const fetchData = async () => {
        const m = [];

        const filter = { aggregate: true, groupBy: 'mode', interval }
        if (from) filter.from = moment(from).format('X');
        if (to) filter.to = moment(to).format('X');

        await dataProvider(GET_LIST, 'paths/saved', {
            filter,
            sort: { field: 'createdAt', order: 'ASC' }
        }).then((routes) => {
            routes.data.forEach(e => {
                m.push({ x: e.id.mode, y: e.meterSum, color: modeColorDictionary[e.id.mode] });
            })
        }).catch(console.error);

        setData({ loading: false, modes: m });
    };

    useEffect(() => {
        fetchData();
    }, [from, to, interval]);

    return (
        <>
            {
                data.loading ? <div style={{ padding: 20 }}> <LinearProgress style={{ margin: "auto" }} /> </div>
                    : data.modes.length ? <RenderGraph height={height} width={width} data={data.modes} type="vertical_bar" yAxisText={yAxisText} xAxisText={xAxisText} /> : <div>No data to show</div>
            }
        </>
    );
};
