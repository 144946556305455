import React from 'react';
import {
    List, Datagrid, ShowButton, TextInput, ReferenceInput,
    Show, SimpleShowLayout, Filter,
    TextField, DateField, SelectInput,
    Edit, SimpleForm, ReferenceField, required
} from 'react-admin';
import { EditToolbar, FullNameField } from '../helper';
import { locales, paymentMethods } from '../config';

const PaymentMethodFilters = (props) => (
    <Filter {...props}>
        <SelectInput label="Provider" source="provider" allowEmpty={false} choices={paymentMethods.provider} />
        <SelectInput label="Status" source="status" allowEmpty={false} choices={paymentMethods.status} />
        <TextInput label="Last 4" source="details.last4" />
        <TextInput label="Brand" source="details.brand" />
        <ReferenceInput label="User" source="userID" reference="users" allowEmpty={false} sort={{ field: 'firstName', order: 'DESC' }} perPage={0}>
            <SelectInput optionText={<FullNameField />} />
        </ReferenceInput>
    </Filter>
);

export const PaymentMethodsList = (props) => (
    <List {...props}
        filters={<PaymentMethodFilters />}
        bulkActionButtons={false}
        sort={{ field: 'updatedAt', order: 'ASC' }}>
        <Datagrid>
            <TextField label="ID" source="id" />
            <ReferenceField label="User" source="userID" reference="users" link="show">
                <FullNameField />
            </ReferenceField>
            <TextField label="Provider" source="provider" />
            <TextField label="Brand" source="details.brand" />
            <TextField label="Last 4" source="details.last4" />
            <TextField label="Status" source="status" />
            <ShowButton />
        </Datagrid>
    </List>
);

export const PaymentMethodsShow = (props) => (
    <Show {...props} >
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <ReferenceField label="User" source="userID" reference="users" link="show">
                <FullNameField />
            </ReferenceField>
            <TextField label="Provider" source="provider" />
            <TextField label="Type" source="details.type" />
            <TextField label="Brand" source="details.brand" />
            <TextField label="Last 4" source="details.last4" />
            <TextField label="Expiry month" source="details.expiryMonth" />
            <TextField label="Expiry year" source="details.expiryYear" />
            <TextField label="Status" source="status" />
            <DateField label="Created" source="createdAt" locales={locales.sl} showTime />
            <DateField label="Updated" source="updatedAt" locales={locales.sl} showTime />
        </SimpleShowLayout>
    </Show>
);

export const PaymentMethodsEdit = (props) => (
    <Edit {...props}>
        <SimpleForm toolbar={<EditToolbar />} redirect="show">
            <TextField label="ID" source="id" />
            <SelectInput label="Status" source="status" choices={paymentMethods.status} validate={[required()]}/>
            <DateField label="Created" source="createdAt" locales={locales.sl} showTime />
            <DateField label="Updated" source="updatedAt" locales={locales.sl} showTime />
        </SimpleForm>
    </Edit>
);