import React from 'react';
import {
    List, Datagrid, ShowButton, Show, TextField, BooleanField, Filter, ReferenceInput,
    ReferenceField, NumberField, DateField, ArrayField, SimpleShowLayout, SelectInput
} from 'react-admin';
import { locales } from '../config'
import { FullNameField } from '../helper';
import { GoogleMap, withScriptjs, withGoogleMap, Marker } from "react-google-maps";

const MyMapComponent = withScriptjs(withGoogleMap(props => {
    return <GoogleMap
        defaultZoom={14}
        defaultCenter={{ lat: props.geoLocation.latitude, lng: props.geoLocation.longitude }}>
        <Marker position={{ lat: props.geoLocation.latitude, lng: props.geoLocation.longitude }} />
    </GoogleMap>;
}));

const MapWrapper = props => {
    if (!props.record.geoLocation || !props.record.geoLocation.latitude || !props.record.geoLocation.longitude) {
        return <div></div>;
    } else {
        return <MyMapComponent
            geoLocation={props.record.geoLocation}
            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `300px`, width: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
        />;
    }
}


const PreferencesFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="User" source="userID" reference="users" allowEmpty={false} sort={{ field: 'firstName', order: 'DESC' }} perPage={0}>
            <SelectInput optionText={<FullNameField />} />
        </ReferenceInput>
    </Filter>
);


export const PreferencesList = (props) => (
    <List {...props} filters={<PreferencesFilter />} bulkActionButtons={false} sort={{ field: 'updatedAt', order: 'ASC' }}>
        <Datagrid>
            <TextField label="ID" source="id" />
            <ReferenceField label="User" source="userID" reference="users" link="show">
                <FullNameField />
            </ReferenceField>
            <NumberField label="Price" source="preferences.price" />
            <NumberField label="Environmental" source="preferences.environmental" />
            <NumberField label="Duration" source="preferences.duration" />
            <NumberField label="Convenience" source="preferences.convenience" />
            <ShowButton />
        </Datagrid>
    </List>
);

export const PreferencesShow = (props) => (
    <Show {...props} >
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <ReferenceField label="User" source="userID" reference="users" link="show">
                <FullNameField />
            </ReferenceField>
            <NumberField label="Price" source="preferences.price" />
            <NumberField label="Environmental" source="preferences.environmental" />
            <NumberField label="Duration" source="preferences.duration" />
            <NumberField label="Convenience" source="preferences.convenience" />
            <BooleanField label="Transit" source="modes.transit" />
            <BooleanField label="Walking" source="modes.walking" />
            <BooleanField label="Cycling" source="modes.cycling" />
            <BooleanField label="Car" source="modes.car" />
            <BooleanField label="Micro Mobility" source="modes.microMobility" />
            <ArrayField label="Locations" source="locations" >
                <Datagrid>
                    <TextField label="Name" source="name" />
                    <MapWrapper source='geoLocation' />
                </Datagrid>
            </ArrayField>
            <DateField label="Created" source="createdAt" locales={locales.sl} showTime />
            <DateField label="Updated" source="updatedAt" locales={locales.sl} showTime />
        </SimpleShowLayout>
    </Show>
);