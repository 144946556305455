import { CREATE, UPDATE } from 'react-admin';

export default async function (type, data) {

    switch (type) {
        case CREATE:
            return modifyBody(data);
        case UPDATE:
            return modifyBody(data);
        default:
            return data;
    }

};

function modifyBody(data) {
    if (data?.company?.email?.address) {
        data.companyEmail = data.company.email.address;
    }
    if (!data.password) {
        delete data.password;
    }
    return data;
}