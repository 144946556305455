import React, { cloneElement } from 'react';
import get from 'lodash/get';

import { TopToolbar, useListContext, useRedirect, sanitizeListRestProps, Toolbar, SaveButton, EditButton, Labeled } from 'react-admin';
import BackArrow from '@material-ui/icons/ArrowBackIos';
import Button from '@material-ui/core/Button';

import countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
const countryList = countries.getNames("en");
export const getCountries = Object.keys(countryList).map(c => { return { id: countries.alpha2ToAlpha3(c), name: Array.isArray(countryList[c]) ? countryList[c][0] : countryList[c] } });

export const EditToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
)

export const HideEditButton = ({ basePath, data }) => {
    return data?.archived ? <TopToolbar /> : (
        <TopToolbar>
            <EditButton basePath={basePath} record={data} />
        </TopToolbar>
    );
}

export const FullNameField = ({ record = {} }) => <span style={{ fontSize: "14px" }}> {record.firstName} {record.lastName}</span>;

export const Amount = ({ record = {} }) => <span style={{ fontSize: "14px" }}> {(Math.round(record.amount || 0 * 1.0) / 100).toFixed(2)} {record.currency || ''}</span>;

export const MyImageComponent = ({ source, record = {} }) => {
    const value = get(record, source);
    if (value?.data) return <> <div><Labeled label="Image" /></div> <img style={{ maxHeight: '10rem', cursor: 'pointer' }} src={value.data} onClick={(e) => { e.stopPropagation(); window.open(value.data); }}></img> </>;
    return null;
};

export const convertToISO = (date) => {
    if (!date) return date;
    const d = new Date(date);
    const iso = d.toISOString();
    return iso.substring(0, iso.indexOf(':', iso.indexOf(':') + 1));
}

export const BackButtonToolbar = (props) => (
    <TopToolbar>
        <Button size="small" color="primary" onClick={() => props.history?.goBack()} ><BackArrow color="primary" fontSize="small" />Back</Button>
    </TopToolbar>
);

export const RecordsToolbar = (props) => {
    const {
        className,
        filters,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        showFilter
    } = useListContext();
    const redirect = useRedirect();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <Button size="small" color="primary" onClick={() => redirect('list', '/plugins')}><BackArrow color="primary" fontSize="small" />Back</Button>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
        </TopToolbar>
    );
};