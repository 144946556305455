import React from 'react';
import axios from 'axios';
import { services } from '../config';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const sendConfirmation = (type, userID) => {
    const toastOptions = {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
        hideProgressBar: true,
        pauseOnHover: true,
        draggable: false,
    };

    axios.post(`${services.users}/api/confirmations`, {
        "userID": userID,
        "confirmation": type
    }, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res => {
        toast.success('Successfully sent.', toastOptions);
    }).catch(err => {
        toast.error(err.response.data.message, toastOptions);
        throw Error(err.response.data.message);
    });
}

const handleClick = e => {
    const { type, userID } = JSON.parse(e.currentTarget.name);
    confirmAlert({
        title: 'Confirm to send',
        buttons: [
            {
                label: 'Send',
                onClick: () => sendConfirmation(type, userID)
            },
            {
                label: 'Cancel',
                onClick: () => {
                    return;
                }
            }
        ],
        closeOnClickOutside: true,
        closeOnEscape: true
    });
}

const RenderButton = props => {
    const data = { type: props.type, userID: props.userID };
    return <Button name={JSON.stringify(data)} variant="contained" color="primary" onClick={handleClick}>Send confirmation</Button>;
}

const ConfirmationButton = props => {
    const { type, record } = props;
    if (!type || !record) return null;

    const { email, telephone, company, id } = record;
    switch (type) {
        case 'privateEmail':
            return email?.address && !email?.confirmed ? <RenderButton type={type} userID={id} key={id} /> : null;
        case 'telephone':
            return telephone?.number && telephone?.countryCode && !telephone?.confirmed ? <RenderButton type={type} userID={id} key={id} /> : null;
        case 'businessEmail':
            return company?.email?.address && !company?.email?.confirmed ? <RenderButton type={type} userID={id} key={id} /> : null;
        default:
            return null;
    }
}

export default ConfirmationButton;