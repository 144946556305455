import { GET_LIST, GET_ONE, CREATE, UPDATE, GET_MANY, DELETE } from 'react-admin';
import { modifyUrl, modifyId, modifyData } from './helper';
import flat from 'flat';
import axios from 'axios';
import { toast } from 'react-toastify';

export default async function (type, resource, params) {

    const options = {
        baseURL: `${modifyUrl(resource)}/api`,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        params: new URLSearchParams(),
        responseType: 'json',
    };

    if (params?.filter) {
        const flatFilter = flat({ ...params.filter });
        for (let key in flatFilter) {
            options.params.append(key.startsWith('_') ? key.substring(1, key.length) : key, flatFilter[key]); //Underscore used for defining 'to' filters.
        }
    }

    if (params?.pagination?.perPage && params?.pagination?.page) {
        options.params.append('skip', params.pagination.perPage * (params.pagination.page - 1));
        options.params.append('limit', params.pagination.perPage);
    }

    if (params?.sort?.order && params?.sort?.field) {
        options.params.append('sort', `${params.sort.order === 'DESC' ? '' : '-'}${params.sort.field}`);
    }

    params.data = await modifyData(type, resource, params.data);

    switch (type) {
        case GET_LIST:
            options.method = 'GET';
            options.url = `/${resource}`;
            break;
        case GET_ONE:
            options.method = 'GET';
            options.url = `/${resource}/${params.id}`;
            break;
        case CREATE:
            options.method = 'POST';
            options.url = `/${resource}`;
            options.data = modifyId('out', params.data);
            break;
        case UPDATE:
            options.method = 'PUT';
            options.url = `/${resource}/${params.id}`;
            options.data = modifyId('out', params.data);
            break;
        case GET_MANY:
            options.method = 'GET';
            options.url = `/${resource}`;
            options.params.append('_ids', params.ids.join(','));
            break;
        case DELETE:
            options.method = 'DELETE';
            options.url = `/${resource}/${params.id}`;
            options.data = { archived: true };
            break;
        default:
            throw new Error(`Preparing request undefined type: ${type}.`);
    }
    
    return axios(options)
        .then(res => {
            res.data = modifyId('in', res.data);
            switch (type) {
                case GET_LIST:
                    return { data: res.data, total: parseInt(res.headers['x-total-count']) || res.data.length };
                default:
                    return { data: res.data };
            }
        })
        .catch(err => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 5000,
                hideProgressBar: true,
                pauseOnHover: true,
                draggable: false,
            });
            throw Error(err.response.data.message);
        });
}
