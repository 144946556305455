import { CREATE, UPDATE } from 'react-admin';
import { convertFileToBase64 } from '../dataProvider/helper'

export default async function (type, data) {

    switch (type) {
        case CREATE:
            return await parepareImage(data);
        case UPDATE:
            return await parepareImage(data);
        default:
            return data;
    }

};

async function parepareImage(data) {
    if (data?.customization?.image?.data) {
        if (data.customization.image.data.includes('blob:')) {
            data.customization.image = {
                title: data.customization.image.title,
                data: await convertFileToBase64(data.customization.image),
            }
        }
    }
    return data;
}