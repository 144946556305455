function serviceURLs(nodeENV) {
    switch (nodeENV) {
        case 'staging':
            return {
                users: `https://nomo.koin.lgm.fri.ingress.si/usr`,
                payments: `https://nomo.koin.lgm.fri.ingress.si/pym`,
                routes: `https://nomo.koin.lgm.fri.ingress.si/rts`,
                informator: `https://nomo.koin.lgm.fri.ingress.si/inf`,
                tspOrchestrator: `https://nomo.koin.lgm.fri.ingress.si/tor`
            }
        default:
            return {
                users: process.env.MOS_DASHBOARD_SERVICE_USERS,
                payments: process.env.MOS_DASHBOARD_SERVICE_PAYMENTS,
                routes: process.env.MOS_DASHBOARD_SERVICE_ROUTES,
                informator: process.env.MOS_DASHBOARD_SERVICE_INFORMATOR,
                tspOrchestrator: process.env.MOS_DASHBOARD_SERVICE_TSP_ORCHESTRATOR,
            }
    }
}

const language = [
    { id: 'en', name: 'English' },
    { id: 'sl', name: 'Slovenian' }
]

module.exports = {
    // services: serviceURLs(process.env.NODE_ENV),
    services: serviceURLs('staging'),
    roles: {
        ADMIN: 'administrator',
        MEMBER: 'member',
    },
    googleMapsKey: process.env.MOS_DASHBOARD_GMAPS_API_KEY,
    locales: {
        sl: 'sl-SI'
    },
    users: {
        roles: [
            { id: 'administrator', name: 'Administrator' },
            { id: 'member', name: 'Member' },
        ],
        status: [
            { id: 'unconfirmed', name: 'Unconfirmed' },
            { id: 'confirmed', name: 'Confirmed' },
            { id: 'banned', name: 'Banned' },
        ],
        language
    },
    paymentMethods: {
        provider: [
            { id: 'stripe', name: 'Stripe' },
        ],
        status: [
            { id: 'active', name: 'Active' },
            { id: 'hidden', name: 'Hidden' },
            { id: 'blocked', name: 'Blocked' }
        ]
    },
    payments: {
        status: [
            { id: 'processing', name: 'Processing' },
            { id: 'success', name: 'Success' },
            { id: 'fail', name: 'Fail' },
            { id: 'cancel', name: 'Cancel' },
        ]
    },
    news: {
        types: [
            { id: 'news', name: 'News' },
            { id: 'onboarding', name: 'Onboarding' }
        ],
        language
    },
    providers: {
        modes: [
            { id: 'taxi', name: 'taxi' }
        ]
    }
}