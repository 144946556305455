import { CREATE, UPDATE } from 'react-admin';
import { convertFileToBase64 } from '../dataProvider/helper'

export default async function (type, data) {
    switch (type) {
        case CREATE:
            return await parepareImage(data);
        case UPDATE:
            return await parepareImage(data);
        default:
            return data;
    }
};

async function parepareImage(data) {
    if (data?.information?.length) {
        for (let i = 0; i < data.information.length; i += 1) {
            if (data?.information[i]?.image?.data?.includes('blob:')) {
                data.information[i].image = {
                    title: data.information[i].image.title,
                    data: await convertFileToBase64(data.information[i].image),
                }
            }
        }
    }
    return data;
}