import React, { useState } from "react";
import '../../node_modules/react-vis/dist/style.css';

import NewUsers from './newUsers';
import Graphs from './graphs';
import LatestPayments from './latestPayments';
import Map from './map';

import { useStyles } from './styles';

import { Card, Tab, Tabs, Grid } from '@material-ui/core';
import { SanitizedGrid } from '../layout/index';
import { TabPanel } from './helper'

export default () => {

    const [tabIndex, setTabIndex] = useState(0);

    const classes = useStyles();

    return (
        <SanitizedGrid container spacing={2}>
            <Grid item xs={tabIndex === 0 ? 8 : 12}>
                <Card className={classes.root} style={{ minHeight: '600' }}>
                    <Tabs
                        value={tabIndex}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={(e, newValue) => setTabIndex(newValue)}>
                        <Tab label="Graphs" />
                        <Tab label="Map" />
                    </Tabs>
                    <TabPanel value={tabIndex} index={0}>
                        <Graphs />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <Map />
                    </TabPanel>
                </Card>
            </Grid>
            { tabIndex === 0 &&
                <Grid item xs={4}>
                    <NewUsers />
                    <LatestPayments />
                </Grid>
            }
        </SanitizedGrid >
    );
}
