import React from 'react';
import { locales, users } from '../config';
import {
    List, Datagrid, ShowButton, SelectInput, Filter, SelectField, Show, Edit, SimpleForm, required,
    TextField, EmailField, BooleanField, BooleanInput, NumberField, NumberInput, TextInput, DateField, ReferenceField, ReferenceInput, ArrayField, SimpleShowLayout
} from 'react-admin';
import { EditToolbar, getCountries } from '../helper';
import ConfirmationButton from './helper';

import Grid from '@material-ui/core/Grid';
import { SanitizedGrid } from '../layout/index';
import '../layout/style.css';

const UserFilters = (props) => (
    <Filter {...props}>
        <TextInput label="Private Email" source="email.address" />
        <TextInput label="Company Email" source="company.email.address" />
        <TextInput label="Telephone" source="telephone.number" />
        <ReferenceInput label="Company" source="company.companyID" reference="companies" allowEmpty={false} sort={{ field: 'name', order: 'DESC' }} perPage={0}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput label="Role" allowEmpty={false} source="role" choices={users.roles} />
        <SelectInput label="Status" allowEmpty={false} source="status" choices={users.status} />
        <TextInput label="External ID" source="externalID" />
    </Filter>
);

export const UserList = (props) => (
    <List {...props}
        filters={<UserFilters />}
        bulkActionButtons={false}
        sort={{ field: 'updatedAt', order: 'ASC' }}>
        <Datagrid>
            <TextField label="ID" source="id" />
            <TextField label="Firstname" source="firstName" />
            <TextField label="Lastname" source="lastName" />
            <EmailField label="Email" source="email.address" />
            <TextField label="Role" source="role" />
            <ReferenceField label="Company" source="company.companyID" reference="companies" link="show">
                <TextField source="name" />
            </ReferenceField>
            <ShowButton />
        </Datagrid>
    </List >
);

export const UserShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="External ID" source="externalID" />
            <TextField label="Firstname" source="firstName" />
            <TextField label="Lastname" source="lastName" />
            <TextField label="Role" source="role" />
            <TextField label="Status" source="status" />
            <EmailField label="Private email" source="email.address" />
            <ConfirmationButton type="privateEmail" />
            <BooleanField label="Private email confirmed" source="email.confirmed" />
            <TextField label="Country Code" source="telephone.countryCode" />
            <TextField label="Telephone" source="telephone.number" />
            <ConfirmationButton type="telephone" />
            <BooleanField label="Telephone confirmed" source="telephone.confirmed" />
            <ReferenceField label="Company" source="company.companyID" reference="companies" link="show">
                <TextField source="name" />
            </ReferenceField>
            <EmailField label="Company email" source="company.email.address" />
            <ConfirmationButton type="businessEmail" />
            <BooleanField label="Company email confirmed" source="company.email.confirmed" />
            <TextField label="Address" source="address.address" />
            <TextField label="City" source="address.city" />
            <TextField label="Zip code" source="address.zipCode" />
            <SelectField label="Country" source="address.country" choices={getCountries} />
            <NumberField label="Longitude" source="address.geoLocation.lng" options={{ maximumFractionDigits: 8 }} />
            <NumberField label="Latitude" source="address.geoLocation.lat" options={{ maximumFractionDigits: 8 }} />
            <BooleanField label="Terms and conditions consent" source="consent.termsAndConditions" />
            <BooleanField label="GDPR consent" source="consent.publicAppUsage" />
            <DateField label="Created" source="createdAt" locales={locales.sl} showTime />
            <DateField label="Updated" source="updatedAt" locales={locales.sl} showTime />
        </SimpleShowLayout>
    </Show>
);

const Inputs = (props) => (
    <SanitizedGrid container spacing={2}>
        {props.isEdit ? <Grid item xs={12}><TextInput disabled className="rem" label="ID" source="id" /></Grid> : null}
        <Grid item xs={12}><TextInput label="External ID" source="externalID" /></Grid>
        <Grid item xs={12}><TextInput label="Firstname" source="firstName" validate={[required()]} /></Grid>
        <Grid item xs={12}><TextInput label="Lastname" source="lastName" validate={[required()]} /></Grid>
        <Grid item xs={12}><SelectInput label="Role" source="role" choices={users.roles} validate={[required()]} /></Grid>
        <Grid item xs={12}><SelectInput label="Status" source="status" choices={users.status} validate={[required()]} /></Grid>
        <Grid item xs={12}><TextInput disabled label="Private email" source="email.address" type="email" /></Grid>
        <Grid item xs={12}><TextInput label="Country Code" source="telephone.countryCode" validate={[required()]} /></Grid>
        <Grid item xs={12}><TextInput label="Telephone" source="telephone.number" validate={[required()]} /></Grid>
        <Grid item xs={12}><TextInput label="Password" source="password" /></Grid>
        <Grid item xs={12}>
            <ReferenceInput label="Company" source="company.companyID" reference="companies" allowEmpty sort={{ field: 'name', order: 'DESC' }} perPage={0}>
                <SelectInput optionText="name" />
            </ReferenceInput>
        </Grid>
        <Grid item xs={12}><TextInput label="Company email" source="company.email.address" type="email" /></Grid>
        <Grid item xs={12}><TextInput label="Address" source="address.address" /></Grid>
        <Grid item xs={12}><TextInput label="City" source="address.city" /></Grid>
        <Grid item xs={12}><TextInput label="Zip code" source="address.zipCode" /></Grid>
        <Grid item xs={12}><SelectInput label="Country" optionText="name" source="address.country" choices={getCountries} /></Grid>
        <Grid item xs={12}><NumberInput label="Longitude" source="address.geoLocation.lng" /></Grid>
        <Grid item xs={12}><NumberInput label="Latitude" source="address.geoLocation.lat" /></Grid>
        <Grid item xs={12}><BooleanInput label="Terms and conditions consent" source="consent.termsAndConditions" /></Grid>
        <Grid item xs={12}><BooleanInput label="GDPR consent" source="consent.publicAppUsage" /></Grid>
    </SanitizedGrid>
);

export const UserEdit = (props) => (
    <Edit {...props}>
        <SimpleForm toolbar={<EditToolbar />} redirect="show">
            <Inputs isEdit={true} />
        </SimpleForm>
    </Edit>
);